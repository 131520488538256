export const state = () => ({
  name: 'Software Design & Principles',
  startDate: '2025/06/18', // yyyy/mm/dd
  startDateText: '18 June 2025',
  nextBatchDate: '18 Sept 2025',
  courseLevel: "Level - Intermediate",
  isSoldOut: false,
  size: 12,
  price: 3290,
  weeks: 8,
  hours: 96,
  personas: [
    {
      name: 'Bootcamp or Uni Graduates',
      testimony:
        'If you’ve just graduated from a university or bootcamp, this program is sure to give you the advanced skills of software engineering to boost your confidence and set you apart from thousands of other students with similar qualifications.',
      photo: require('../assets/student.png'),
    },
    {
      name: 'Devs or Software Prof.',
      testimony:
        'Already started your career? This program provides you the expert skills that are needed to help you grow in your career. Industry needs people who can work independently building great products. This program will teach you exactly that.',
      photo: require('../assets/devs.png'),
    },
    {
      name: 'Entrepreneurs',
      testimony:
        'Have a great idea? Great! Taking part in this program will help you build a highly scalable product. Doing things right is important before the product becomes bulky and this program helps you start on the right foot. ',
      photo: require('../assets/entrepreneur.png'),
    },
    {
      name: 'Self Learner',
      testimony:
        'Tired of beginner level recorded online courses? Want to get better at software development? This is the program where you learn real skills taught by industry experts. ',
      photo: require('../assets/freelancer.png'),
    },
  ],
  faqs: [
    {
      q: 'Do I need any coding skills?',
      a: [
        'Yes! This program is not beginner level program but it is designed for anyone who has at least basic programming knowledge. You must be able to write loops, conditional logic, functions and classes.',
      ],
    },
    {
      q: 'I have already finished a bootcamp, why do I need this program?',
      a: [
        'Having finished a bootcamp is perfect preparation for this program. As this program is made to give you expert skills, through this course you will accelerate your career growth and set yourself apart from others.',
      ],
    },
    {
      q:
        'Do I need to know any specific language like Java, C++, Python or similar language?',
      a: [
        'Nope! This program is language agnostic and students can use any language during the program.',
        'The program material provides code samples in Javascript, Java and Python. You can always ask for code samples in the language you like. We do recommend that you use any object oriented language.',
      ],
    },
    {
      q: "My job doesn't include a lot of coding, can I still join?",
      a: [
        'Although coding is an integral part of the program, we are happy to discuss the best way forward for you. Please contact us at contact@codingdeepdive.com so that we can discuss the right approach for you.',
      ],
    },
  ],
  learningMethods: [
    {
      name: 'Online Teaching Session',
      testimony:
        'Online teaching sessions (zoom meetings) are where the instructor will teach you all the content of the program and you can ask him any questions. Instructors are there to help you understand every concept and its application.',
      photo: require('../assets/online.png'),
    },
    {
      name: 'Tasks and Project Work',
      testimony:
        'The tasks and project work is for you to apply the concept explained during the online sessions. These tasks are designed to enhance and deepen your knowledge of software engineering.',
      photo: require('../assets/know.png'),
    },
    {
      name: 'Mentoring and Interview Prep',
      testimony:
        'Career mentoring sessions are scheduled to help students get ready for industry including reviews of resume, cover letter, portfolios and social presence.',
      photo: require('../assets/stack.png'),
    },
    {
      name: 'Saturday Support/Code Reviews',
      testimony:
        'In addition to online live sessions, saturdays are designated to provide support for students for solving tasks and one-on-one discussions. Code reviews are conducted on saturdays so that students can continuously improve their performance.',
      photo: require('../assets/cal.png'),
    },
  ],

  topics: [
    {
      id: 0,
      category: 'Programming',
      title: 'Data Structure & Algorithms',
      weight: '6 points',
      desc:
        'Learn how to store and access information with speed and efficiency',
      img: require('../assets/ds.png'),
      details: '/software-design-curriculum#data-structure'
    },
    {
      id: 1,
      category: 'Programming',
      title: 'Clean Code',
      weight: '2 points',
      desc:
        'Learn the best practices of writing clean, maintainable and readable code.',
      img: require('../assets/cc.png'),
      details: '/software-design-curriculum#clean-code'
    },
    {
      id: 2,
      category: 'Career',
      title: 'Coding Interview Skills',
      weight: '4 points',
      desc:
        'Learn to Solve Coding Interview Questions with Speed and Quality.',
      img: require('../assets/al.png'),
      details: '/software-design-curriculum#career'
    },
    {
      id: 3,
      category: 'Design',
      title: 'OOP Principles',
      weight: '4 points',
      desc: 'Learn to design software using Object Oriented Principles.',
      img: require('../assets/oop.png'),
      details: '/software-design-curriculum#oop-principles'
    },
    {
      id: 4,
      category: 'Design',
      title: 'Design Patterns',
      weight: '5 points',
      desc:
        'Most problems follow patterns and solutions too. Learn the well known patterns to make scalable softwares.',
      img: require('../assets/dp.png'),
      details: '/software-design-curriculum#design-patterns'
    },
    {
      id: 5,
      category: 'Design',
      title: 'Database Design',
      weight: '5 points',
      desc:
        'Database needs a good design for efficient storage and fast access. Learn the database design principles.',
      img: require('../assets/db.png'),
      details: '/software-design-curriculum#database-design',
    },
  ]
});

export const mutations = {
  add(state, text) {
    state.list.push({
      text,
      done: false
    });
  },
  remove(state, {
    todo
  }) {
    state.list.splice(state.list.indexOf(todo), 1);
  },
  toggle(state, todo) {
    todo.done = !todo.done;
  }
};
