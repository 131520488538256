export const state = () => ({
  name: 'Frontend Development with React and VueJS',
  startDate: '2025/06/18', // yyyy/mm/dd
  startDateText: '18 June 2025',
  nextBatchDate: '18 Sept 2025',
  courseLevel: "Level - Intermediate",
  isSoldOut: false,
  size: 8,
  price: 3860,
  weeks: 8,
  hours: 96,
  personas: [
    {
      name: 'Bootcamp or Uni Graduates',
      testimony:
        'If you’ve just graduated from a university or bootcamp, this program is sure to give you the advanced skills of software engineering to boost your confidence and set you apart from thousands of other students with similar qualifications.',
      photo: require('../assets/student.png'),
    },
    {
      name: 'Devs or Software Prof.',
      testimony:
        'Already started your career? This program provides you the expert skills that are needed to help you grow in your career. The industry needs people who can work independently to build great products. This program will teach you exactly that.',
      photo: require('../assets/devs.png'),
    },
    {
      name: 'Entrepreneurs',
      testimony:
        'Have a great idea? Great! Taking part in this program will help you build a highly scalable product. Doing things right is important before the product becomes bulky and this program helps you start on the right foot. ',
      photo: require('../assets/entrepreneur.png'),
    },
    {
      name: 'Self Learner',
      testimony:
        'Tired of beginner-level recorded online courses? Want to get better at software development? This is the program where you learn real skills taught by industry experts. ',
      photo: require('../assets/freelancer.png'),
    },
  ],
  faqs: [
    {
      q: 'Do I need any coding skills?',
      a: [
        'Yes! This program is intermediate to advanced level of web development. It is designed for people having experience in programming with HTML, CSS, and Javascript. You must be able to create web pages with HTML and CSS and be able to write loops, conditional logic, functions, and classes in Javascript. ',
      ],
    },
    {
      q: 'I have already finished a bootcamp, why do I need this program?',
      a: [
        'Although coding bootcamps expose students to various programming concepts, a student is hardly ready for a real job in a company. This program focuses on real hands-on skills in frontend frameworks such as React and VueJS and helps students to get ready to crack coding interviews and land a job quickly.',
      ],
    },
    {
      q:
        'Do I need to know any specific programming languages?',
      a: [
        'Yes! This program requires students to know HTML, CSS and Javascript.'
      ],
    },
    {
      q: "I am new to programming, can I join this program to get better at programming?",
      a: [
        'No! This program is specifically designed to teach React and Vue frameworks. You should already be able to code in HTML, CSS and Javascript.',
        'If you want to get better in programming, please see the program Web Development with Javascript.'
      ],
    },
  ],
  learningMethods: [
    {
      name: 'Online Teaching Session',
      testimony:
        'Online teaching sessions (zoom meetings) are where the instructor will teach you all the content of the program and you can ask him any questions. Instructors are there to help you understand every concept and its application.',
      photo: require('../assets/online.png'),
    },
    {
      name: 'Tasks and Project Work',
      testimony:
        'The tasks and project work is for you to apply the concept explained during the online sessions. These tasks are designed to enhance and deepen your knowledge of software engineering.',
      photo: require('../assets/know.png'),
    },
    {
      name: 'Mentoring and Interview Prep',
      testimony:
        'Career mentoring sessions are scheduled to help students get ready for industry including reviews of resume, cover letter, portfolios and social presence.',
      photo: require('../assets/stack.png'),
    },
    {
      name: 'Saturday Support/Code Reviews',
      testimony:
        'In addition to online live sessions, saturdays are designated to provide support for students for solving tasks and one-on-one discussions. Code reviews are conducted on saturdays so that students can continuously improve their performance.',
      photo: require('../assets/cal.png'),
    },
  ],

  topics: [
    {
      id: 0,
      category: 'Programming',
      title: 'Javascript and NodeJS',
      weight: '2 points',
      desc:
        'Learn advanced topics of javascript and NodeJS',
      img: require('../assets/ffw/1.png'),
      details: '/frontend-frameworks-curriculum#js-nodejs'
    },
    {
      id: 1,
      category: 'Programming',
      title: 'ReactJS Development',
      weight: '5 points',
      desc:
        'Learn to build user interfaces with ReactJS with components and state management.',
      img: require('../assets/ffw/2.png'),
      details: '/frontend-frameworks-curriculum#reactjs'
    },
    {
      id: 2,
      category: 'Programming',
      title: 'REST API Consumption with Redux',
      weight: '3 points',
      desc:
        'Learn to fetch and consume data from REST APIs and maintain global states with Redux',
      img: require('../assets/ffw/3.png'),
      details: '/frontend-frameworks-curriculum#restapi'
    },
    {
      id: 3,
      category: 'Programming',
      title: 'VueJS with VueX',
      weight: '5 points',
      desc: 'Learn to build modern web application frontends with VueJS and VueX/Pinia.',
      img: require('../assets/ffw/4.png'),
      details: '/frontend-frameworks-curriculum#vuejs-vuex'
    },
    {
      id: 4,
      category: 'Design',
      title: 'Product Design and Development',
      weight: '3 points',
      desc:
        'From idea to implementation, learn everything that goes into product development.',
      img: require('../assets/ffw/5.png'),
      details: '/frontend-frameworks-curriculum#product-design'
    },
    {
      id: 5,
      category: 'Career',
      title: 'Coding and behavioral interview skills',
      weight: '2 points',
      desc:
        'Get ready to land your first job with learning how to crack interviews.',
      img: require('../assets/db.png'),
      details: '/frontend-frameworks-curriculum#interview-skills',
    },
  ]
});

export const mutations = {
  add(state, text) {
    state.list.push({
      text,
      done: false
    });
  },
  remove(state, {
    todo
  }) {
    state.list.splice(state.list.indexOf(todo), 1);
  },
  toggle(state, todo) {
    todo.done = !todo.done;
  }
};
