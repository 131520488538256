export const state = () => ({
  name: 'Web Development with Javascript',
  shortDesc: 'Build and Launch your website on Internet with HTML, CSS and Javascript. Learn how to design and develop your website with your favorite styles and learn the most famous programming language of internet - Javascript.',
  startDate: '2025/06/18', // yyyy/mm/dd
  startDateText: '18 June 2025',
  nextBatchDate: '18 Sept 2025',
  courseLevel: "Level - Beginner",
  isSoldOut: false,
  size: 6,
  price: 1600,
  weeks: 4,
  hours: 48,
  personas: [
    {
      name: 'Industry Professionals',
      testimony:
        'Not having IT skills can be damaging to your chances in an industry where programming and coding are part of day to day work. This program gives you the perfect start for training your IT skills.',
      photo: require('../assets/devs.png'),
    },
    {
      name: 'Entrepreneurs',
      testimony:
        'Having a great idea? Great! Taking part in this program will help you build the first version of your web product. Once you know how to start building things with programming, you can grow your skills in any direction.',
      photo: require('../assets/entrepreneur.png'),
    },
    {
      name: 'Curious About Coding',
      testimony:
        'Tired of hunting YouTube videos and \'Monkey see Monkey do\' online courses with no support or motivation? This program will help you understand how to write code like a human and get the right answers to your questions from industry experts.',
      photo: require('../assets/freelancer.png'),
    },
    {
      name: 'School or Uni Students',
      testimony:
        'Have you just graduated from a school or university and want to learn programming? This program will give you a head start into programming and help you boost your confidence.',
      photo: require('../assets/student.png'),
    },
  ],
  learningMethods: [
    {
      name: 'Online Teaching Session',
      testimony:
        'Online teaching sessions (zoom meetings) are where the instructor will teach you all the content of the program and you can ask him any question. The instructor is there to help you understand every concept and its application.',
      photo: require('../assets/online.png'),
    },
    {
      name: 'Tasks and Project Work',
      testimony:
        'The tasks and project work is for you to apply the concept explained during the online sessions. These tasks are designed to enhance and deepen your knowledge of programming.',
      photo: require('../assets/know.png'),
    },
    {
      name: 'Code Mentoring and Bonus Sessions',
      testimony:
        'In extra mentoring sessions, you will learn skills such as Git and web hosting that are needed to launch a real website. These session are organized together with students during the course.',
      photo: require('../assets/stack.png'),
    },
    {
      name: 'Saturday Support/Code Reviews',
      testimony:
        'In addition to online live sessions, saturdays are designated to provide support for students for solving tasks and one-on-one discussions. Code reviews are conducted so that students can continuously improve their performance.',
      photo: require('../assets/cal.png'),
    },
  ],

  topics: [
    {
      id: 0,
      category: 'Design',
      title: 'Website Design - HTML and CSS',
      weight: '4 points',
      desc:
        'Learn how to layout information on Web pages with stunning styles.',
      img: require('../assets/design.png'),
      details: '/web-development-js-curriculum#module-1'
    },
    {
      id: 1,
      category: 'Programming',
      title: 'Logic Programming in Javascript',
      weight: '4 points',
      desc:
        'Learn to program complex logic easily with Javascript.',
      img: require('../assets/logic.png'),
      details: '/web-development-js-curriculum#module-2'
    },
    {
      id: 2,
      category: 'Programming',
      title: 'Advanced Javascript Programming',
      weight: '6 points',
      desc:
        'Learn the advanced feature of Javascript to build complex Web apps.',
      img: require('../assets/advance.png'),
      details: '/web-development-js-curriculum#module-3'
    },
    {
      id: 3,
      category: 'Hosting',
      title: 'Launching Website with Web Hosting',
      weight: '4 points',
      desc: 'Launch your website on Internet and show others your achievement.',
      img: require('../assets/host.png'),
      details: '/web-development-js-curriculum#module-4'
    }
  ],
  faqs: [
    {
      q: 'Do I need any coding skills?',
      a: [
        'No! This program is for absolute beginners and teaches everything from the very beginning.',
      ],
    },
    {
      q: 'What does this course teach me? ',
      a: [
        'This course is designed to teach you the foundational skills to build your programming knowledge. This course explains all concepts using small examples to help you easily learn programming.',
      ],
    },
    {
      q:
        'Can I get a job after this course? ',
      a: [
        'Definitely! There are many jobs that require Javascript for programming. You can also apply jobs related to Front End web development or even build mobile applications.'
      ],
    },
    {
      q: "Will I get a certificate at the end?",
      a: [
        'Yes! You will get a certificate and a reference letter with the details of every topic that this course teaches you.'
      ],
    },
    {
      q: "I want to learn Web design, is this course good for me? ",
      a: [
        'Web design mostly means website design and development and this course teaches you exactly that. The course starts with HTML and CSS and they are exactly for web design.'
      ],
    },
    {
      q: "I am a marketing / management professional working in IT industry. Will this program help me? ",
      a: [
        'While working in IT industry, it is always a good idea to have some understanding of programming. This program can definitely help you learn the basics of programming even if you don’t code later on.'
      ],
    },
  ],
});

export const mutations = {
  add(state, text) {
    state.list.push({
      text,
      done: false
    });
  },
  remove(state, {
    todo
  }) {
    state.list.splice(state.list.indexOf(todo), 1);
  },
  toggle(state, todo) {
    todo.done = !todo.done;
  }
};
